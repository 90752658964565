import React, { Component } from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { rhythm } from '../utils/typography'
import presets, { colors } from '../utils/presets'

class ProjectList extends Component {
  render() {
    const posts = this.props.postEdges
    return (
      <section
        css={{
          display: `grid`,
          gridTemplateColumns: `1fr`,
          gridColumnGap: rhythm(1),
          gridRowGap: rhythm(1),
          [presets.Tablet]: {
            gridTemplateColumns: `1fr 1fr`,
          },
          [presets.Desktop]: {
            gridTemplateColumns: `1fr 1fr 1fr`,
          },
          [presets.Hd]: {
            gridTemplateColumns: `1fr 1fr 1fr 1fr`,
          },
        }}
      >
        {posts.map(({ node }) => {
          const title = get(node, 'frontmatter.title') || node.fields.slug
          const tags = node.frontmatter.tags
          return (
            <div
              key={node.fields.slug}
              css={{
                display: `grid`,
                gridTemplateColumns: `1fr`,
                gridColumnGap: rhythm(1),
              }}
            >
              <div
                css={{
                  display: `grid`,
                  gridTemplateColumns: `1fr`,
                  gridColumnGap: rhythm(1),
                  marginBottom: rhythm(1),
                }}
              >
                <Link className="link-style" to={node.fields.slug}>
                  <Img
                    fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                  />
                </Link>
                <div
                  css={{
                    paddingTop: rhythm(1 / 4),
                    paddingBottom: rhythm(1 / 4),
                    [presets.Desktop]: {
                      paddingTop: rhythm(1),
                      paddingBottom: rhythm(1),
                    },
                  }}
                >
                  <h4
                    css={{
                      marginTop: rhythm(1),
                      marginBottom: rhythm(1 / 8),
                      [presets.Tablet]: {
                        marginTop: 0,
                      },
                    }}
                  >
                    <Link className="link-style" to={node.fields.slug}>
                      {title}
                    </Link>
                  </h4>
                  <small
                    css={{
                      color: colors.ui.calm,
                      marginBottom: rhythm(1 / 2),
                    }}
                  >
                    {tags.map((tag, index) => <Link 
                      css={{
                        border: 'none',
                        boxShadow: 'none',
                        fontWeight: 'normal',
                        marginLeft: '4px',
                        marginRight: '4px'
                      }}
                      key={index} to={`/tags/${tag.slice(1)}`}>{index === tags.length - 1  ? tag : `${tag},`}</Link>)}
                  </small>
                </div>
              </div>
            </div>
          )
        })}
      </section>
    )
  }
}

export default ProjectList
