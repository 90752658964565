import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import ProjectList from '../components/ProjectList'
import { rhythm } from '../utils/typography'

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
    } tagged with "${tag}"`

  return (
    <Layout>
      <h1>{tagHeader}</h1>
      <div css={{ marginBottom: rhythm(1) }}>
        <Link to="/tags">All Tags</Link>
      </div>
      <ProjectList postEdges={edges} />
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            tags
            featuredImage {
              childImageSharp {
                fluid(
                  maxWidth: 200
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
